<template>
  <div>
    <button
      type="button"
      class="btn btn-main button mb-2 btn-cat"
      @click="createMainCategory"
    >
      Create Root Category
    </button>

    <div class="mt-3 list-cat pb-1">
      <template v-for="item in items">
        <div :key="`lv2-${item.id}`">
          <div class="pl-lv2 py-1">
            <div :class="{ menuactive: isActive(item.id, 1) }">
              <div>
                <span title="Root Level 1">
                  <b-button
                    class="p-0"
                    variant="link"
                    v-b-toggle="`collapse-${item.id}`"
                  >
                    <font-awesome-icon
                      :icon="
                        expandList.includes(`collapse-${item.id}`)
                          ? 'chevron-up'
                          : 'chevron-down'
                      "
                    />
                  </b-button>
                  <span
                    @click="
                      updateCategory(item, 1, item, `collapse-${item.id}`)
                    "
                  >
                    {{ item.name }} ({{ item.category_2.length }})</span
                  >
                </span>
                <b-button
                  variant="toggle-tree"
                  class="ml-2"
                  @click="getCategoryData(item, 1, item, `collapse-${item.id}`)"
                >
                  <font-awesome-icon icon="plus" />
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <template v-for="item2 in item.category_2">
          <b-collapse
            visible
            :id="`collapse-${item.id}`"
            :key="`lv1-${item.id}-lv2-${item2.id}`"
          >
            <div>
              <div class="text-left pl-lv3">
                <div
                  :class="{ menuactive: isActive(item2.id, 2) }"
                  class="pl-lv2-box"
                >
                  <b-button
                    class="p-0 text-secondary"
                    variant="link"
                    v-b-toggle="`collapse-${item.id}-${item2.id}`"
                  >
                    <font-awesome-icon
                      :icon="
                        expandList.includes(`collapse-${item.id}-${item2.id}`)
                          ? 'chevron-up'
                          : 'chevron-down'
                      "
                    />
                  </b-button>
                  <span
                    title="Root Level 2"
                    @click="
                      updateCategory(
                        item2,
                        2,
                        item,
                        `collapse-${item.id}-${item2.id}`
                      )
                    "
                  >
                    {{ item2.name }} ({{ item2.category_3.length }})
                  </span>
                  <b-button
                    variant="toggle-tree"
                    class="ml-2"
                    @click="
                      getCategoryData(
                        item2,
                        2,
                        item,
                        `collapse-${item.id}-${item2.id}`,
                        item.id,
                        item2.id
                      )
                    "
                  >
                    <font-awesome-icon icon="plus" />
                  </b-button>
                </div>
              </div>
            </div>
            <template v-for="item3 in item2.category_3">
              <b-collapse
                visible
                :id="`collapse-${item.id}-${item2.id}`"
                :key="`lv1-${item.id}-lvl-2-${item2.id}-lvl3-${item3.id}`"
              >
                <div :key="`lv1-${item.id}-lvl-2-${item2.id}-lvl3-${item3.id}`">
                  <div class="text-left pl-lv3">
                    <div
                      :class="{ menuactive: isActive(item3.id, 3) }"
                      class="pl-lv3-box"
                    >
                      <b-button
                        class="p-0 text-primary"
                        variant="link"
                        v-b-toggle="
                          `collapse-${item.id}-${item2.id}-${item3.id}`
                        "
                      >
                        <font-awesome-icon
                          :icon="
                            expandList.includes(
                              `collapse-${item.id}-${item2.id}-${item3.id}`
                            )
                              ? 'chevron-up'
                              : 'chevron-down'
                          "
                      /></b-button>
                      <span
                        title="Root Level 3"
                        @click="
                          updateCategory(item3, 3, item2, `collapse-${item.id}`)
                        "
                      >
                        {{ item3.name }} ({{ item3.category_4.length }})
                      </span>
                      <b-button
                        variant="toggle-tree"
                        class="ml-2"
                        @click="
                          getCategoryData(
                            item3,
                            3,
                            item2,
                            `collapse-${item.id}`,
                            item.id,
                            item2.id,
                            item3.id
                          )
                        "
                      >
                        <font-awesome-icon icon="plus" />
                      </b-button>
                    </div>
                  </div>
                </div>
                <b-collapse
                  visible
                  :id="`collapse-${item.id}-${item2.id}-${item3.id}`"
                >
                  <template v-for="(item4, key4) in item3.category_4">
                    <div
                      :key="`lv1-${item.id}-lvl-2-${item2.id}-lvl3-${item3.id}-lvl4-${key4}`"
                    >
                      <div class="text-left pl-lv3">
                        <div
                          :class="{ menuactive: isActive(item4.id, 4) }"
                          class="pl-lv4-box"
                          @click="
                            getCategoryData(
                              item4,
                              4,
                              item3,
                              `collapse-${item.id}`,
                              item.id,
                              item2.id,
                              item3.id,
                              item4.id
                            )
                          "
                        >
                          <span title="Root Level 4">
                            {{ item4.name }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </template>
                </b-collapse>
              </b-collapse>
            </template>
          </b-collapse>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      required: true,
      type: Object | Array,
    },
    activeItem: {
      required: false,
      type: String,
    },
    parentList: {
      required: true,
      type: Object | Array,
    },
  },
  data() {
    return {
      expandList: [],
      parentHide: true,
    };
  },
  computed: {},
  mounted() {
    this.$root.$on("bv::collapse::state", (collapseId, show) => {
      if (show) {
        this.expandList.push(collapseId);
      } else {
        this.expandList.splice(this.expandList.indexOf(collapseId));
      }
    });
  },
  methods: {
    createMainCategory() {
      this.$emit("handleSetMainCategoryData");
    },
    createSubCategory(val) {
      this.$emit("handleSetSubCategoryData", val);
    },
    isActive: function (menuItem, lvl) {
      return this.activeItem == `level-${lvl}-id-${menuItem}`;
    },

    getCategoryData(val, lvl, pValue, cId, l_1, l_2, l_3, l_4) {
      this.$emit("handleGetCategoryData", val, lvl, pValue, l_1, l_2, l_3, l_4);
    },
    updateCategory(val, lvl, pValue, cId) {
      this.$emit("getForUpdateCategoryData", val, lvl, pValue);
    },
  },
};
</script>

<style scoped>
.pl-lv2 {
  color: #0d1730;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 16px;
}

.pl-lv2 span {
  cursor: pointer;
}

.pl-lv3 span {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #0d1730;
  font-size: 16px;
  cursor: pointer;
}
.pl-lv2-box {
  padding-inline-start: 6% !important;
}
.pl-lv3-box {
  padding-inline-start: 10% !important;
}
.pl-lv4-box {
  padding-inline-start: 15% !important;
}

.btn-cat {
  width: 100%;
}

.menuactive {
  font-weight: bold;
  background-color: var(--theme-secondary-color);
  padding: 5px 5px;
  /* border-radius: 0.25rem; */
  color: var(--font-color);
}

.menuactive span {
  color: var(--font-color);
}

.circle {
  width: 5px;
}
.list-cat {
  border: 1px solid #d8dbe0;
  max-height: 800px;
  overflow: auto;
}
</style>
